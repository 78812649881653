<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-08-04 11:31:56
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-04 14:14:21
-->
<template>
  <div class="confirm_discount">
    <a-modal
      title="生成销售合同"
      v-model="visible"
      :width="800"
      @ok="onSubmit"
       okText="确定"
      :confirm-loading="confirmLoading"
       :maskClosable="false">
      <a-form-model
        ref="form"
        :model="rowData"
        :rules="formRule"
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item label="请选择银行信息" prop="type">
          <a-radio-group
            @change="handleChange"
            :options="typeOptions"
            v-model="rowData.type"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { Col } from 'ant-design-vue'
import {typeOptions,port} from '../../../utils/resour'
export default {
  name: 'Generate_Linkgap_Contract',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      typeOptions: typeOptions,
      rowData: {},
      order:{},
      port:port,
      // 表单验证
      formRule: {
        type: [{ required: true, message: '请选择银行信息', trigger: 'change' }],
      },
    }
  },

  computed: {},

  created() {},

  methods: {
    onShow(row) {
      this.visible = true
      this.rowData = {},
        this.order = row,
      this.rowData.orderId = row.id
    },

    handleChange(e) {
      const checkRadio = e.target.value
      this.$set(this.rowData, 'type', checkRadio)
    },

    // 提交
    onSubmit() {

      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.confirmLoading = true
        const p = {
          orderId: this.rowData.orderId,
          type: this.rowData.type,
          port: this.port
        }
        this.axios.get(`/api/order/order/orderInfo/lght`, {params: p}).then(res => {
          this.confirmLoading = false;
          this.$message.success(res.message)
          this.$emit('getConfirmDiscount')
          this.visible = false
          this.$emit('onSubmit',this.order)
        }).finally(()=>{
          this.confirmLoading = false;
        })
      })
    }

  },
}
</script>

<style lang='scss' scoped>
</style>