<template>
  <div class="check-order-info">
    <a-modal v-model="visible" title="批量审核" width="80%" style="top: 8px" :footer="null" :maskClosable="false">
      <a-tabs v-model="checkedTabs" @change="callback">
        <a-tab-pane key="base_info" tab="基本信息">
          <a-table :columns="columnsAuditAll" :data-source="dataList" :rowKey="(record) => record.id">
            <span slot="payAmount" slot-scope="text">
              {{ text.toFixed(2) }}
            </span>
            <span slot="policyTotalAmount" slot-scope="text">
              {{ text.toFixed(2) }}
            </span>
            <span slot="rebateTotalAmount" slot-scope="text">
              {{ text.toFixed(2) }}
            </span>
            <span slot="bigAreaName" slot-scope="text, row"> 【{{ row.bigAreaName }}】{{ row.firmName }} </span>
            <span slot="action" slot-scope="text, row">
              <a @click="isShowAll(row)">详情</a>
            </span>
          </a-table>
          <div class="order_info" v-if="isShowAllInfo">
            <div class="order_title">
              <span class="order_code">订单编号： {{ detail.orderCode }}</span>
            </div>
            <a-row>
              <a-col :md="9">
                <div class="line_spacing">创建时间：{{ detail.createTime }}</div>
                <label v-for="(item, index) in contractTypeList" :key="index">
                  <div v-if="item.id == detail.contractType" class="line_spacing">合同类型：{{ item.title }}</div>
                </label>
              </a-col>
              <a-col :md="10">
                <div class="line_spacing">
                  下单方式：{{
                    detail.sourceType && detail.sourceType == 1
                      ? '代客要货'
                      : detail.sourceType == 2
                      ? '自主要货'
                      : '其它'
                  }}
                </div>
                <div class="line_spacing">
                  购货单位： {{ detail.consigneeType == 1 ? '个人' : '公司' }}
                  <span v-if="detail.consigneeType == 2">【{{ detail.companyName }}】</span>
                </div>
              </a-col>
              <a-col :md="3">
                <div style="color: #999999; margin-bottom: 3px">订单状态</div>
                <div style="font-size: 18px; font-weight: bold">{{ detail.orderStatus | status }}</div>
              </a-col>
              <a-col :md="2">
                <div style="color: #999999; margin-bottom: 3px">订单金额</div>
                <div style="font-size: 18px; font-weight: bold" v-if="!detail.payAmount">--</div>
                <div style="font-size: 18px; font-weight: bold" v-else>
                  ¥ {{ detail.payAmount != null ? detail.payAmount.toFixed(2) : 0.0 }}
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="9">
                <div class="line_spacing">
                  发货性质：{{
                    detail.deliveryProperties && detail.deliveryProperties == 1
                      ? '正常发货'
                      : detail.deliveryProperties == 2
                      ? '赠送'
                      : detail.deliveryProperties == 3
                      ? '售后'
                      : '--'
                  }}
                </div>
              </a-col>
              <a-col :md="10">
                <a-col :md="10">
                  <div class="line_spacing">订单备注：{{ detail.orderRemark }}</div>
                </a-col>
              </a-col>
            </a-row>

            <a-row>
              <a-col :md="9">
                <div style="display: flex">
                  附件：<QiniuUpload disabled type="image" :value.sync="imageUrl"></QiniuUpload>
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="split-line" v-if="isShowAllInfo"></div>
          <div class="client_info" v-if="isShowAllInfo">
            <a-divider orientation="left">客户信息</a-divider>
            <a-row>
              <a-col :md="8">
                <div>客户名称：{{ detail.dealerName }}</div>
              </a-col>
              <a-col :md="8">
                <div>收货人：{{ detail.customerName }}</div>
              </a-col>
              <a-col :md="8">
                <div>联系电话：{{ detail.customerContact }}</div>
              </a-col>
              <a-col :md="8">
                <div>
                  收货地址：{{ detail.provinceName }}-{{ detail.cityName }}-{{ detail.areaName }}-{{ detail.address }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="split-line" v-if="isShowAllInfo"></div>
          <a-divider orientation="left" v-if="isShowAllInfo">商品信息</a-divider>
          <div class="product_info" v-if="isShowAllInfo">
            <a-table :data-source="tableData" :rowKey="(record) => record.id" :pagination="false" bordered>
              <a-table-column title="商品图片" data-index="proPicture" align="center">
                <template slot-scope="text">
                  <ZoomMedia style="height: 20px" :value="text"></ZoomMedia>
                </template>
              </a-table-column>
              <a-table-column title="商品名称" data-index="proName"></a-table-column>
              <a-table-column title="分类" data-index="proOrgCodeName" align="center"></a-table-column>
              <a-table-column title="规格" data-index="specValue" align="center"></a-table-column>
              <a-table-column title="品牌" data-index="proBrandName" align="center"></a-table-column>
              <a-table-column title="单位" data-index="proUnit" align="center" :width="100"></a-table-column>

              <a-table-column title="使用政策" data-index="policyName" align="center" :width="150"></a-table-column>
              <a-table-column title="折扣优惠金额" data-index="proRebatePrice" align="center" :width="100">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="政策优惠金额" data-index="proPolicyPrice" align="center" :width="100">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="成交单价" data-index="proCostPrice" align="center">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="购买数量" data-index="purchaseNum" align="center" :width="120"></a-table-column>
              <a-table-column title="实付金额" data-index="proPayAmount" align="center">
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="备注" data-index="remark" align="center"> </a-table-column>
            </a-table>
            <div class="discount">
              <div style="line-height: 35px">
                <span>政策优惠总金额：</span>
                <span style="color: red">{{
                  detail.policyTotalAmount != null ? detail.policyTotalAmount.toFixed(2) : 0.0
                }}</span>
              </div>
              <div>
                <span>折扣优惠总金额：</span>
                <span style="color: red">{{
                  detail.rebateTotalAmount != null ? detail.rebateTotalAmount.toFixed(2) : 0.0
                }}</span>
                <span
                  class="hyperlinks"
                  v-if="detail.dealerRebateUsedDetails && detail.dealerRebateUsedDetails.length > 0"
                  @click="onCheckRebateDetail"
                  >(查看详情)</span
                >
              </div>
            </div>
          </div>
          <div class="split-line"></div>
        </a-tab-pane>
        <a-tab-pane
          key="goods_info"
          v-if="detail.dealerRebateUsedDetails && detail.dealerRebateUsedDetails.length > 0"
          tab="使用折扣"
        >
          <a-table :data-source="dealerRebateUsedDetails" :rowKey="(record) => record.id" :pagination="false" bordered>
            <a-table-column title="折扣名称" data-index="rebateName"></a-table-column>
            <a-table-column title="本次使用折扣金额" data-index="usedAmount"></a-table-column>
            <a-table-column title="使用时间" data-index="createTime"></a-table-column>
          </a-table>
        </a-tab-pane>
        <!-- <a-tab-pane key="ship_info" tab="发货记录" v-if="orderShipInfoList.length > 0">
          <a-table
            :data-source="orderShipInfoList"
            :rowKey="(record) => record.id"
            :scroll="{ x: 1600 }"
            :pagination="false"
            bordered
          >
            <a-table-column title="发货单号" data-index="shipCode" :width="150"></a-table-column>
            <a-table-column title="发货数量" data-index="shipNum" :width="100" align="center"></a-table-column>
            <a-table-column title="发货金额" data-index="actualPrice" :width="120" align="center">
              <template slot-scope="text">{{ text.toFixed(2) }}</template>
            </a-table-column>
            <a-table-column title="承运单位" data-index="deliveryName" :width="150" align="center"></a-table-column>
            <a-table-column title="承运单号" data-index="deliveryNum" :width="150" align="center"></a-table-column>
            <a-table-column title="审核状态" data-index="shippingOrderStatus" align="center">
              <template slot-scope="text">
                <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
                <a-tag v-if="text == 2" color="#67c23a">审核通过</a-tag>
                <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
              </template>
            </a-table-column>
            <a-table-column title="发货状态" data-index="deliveryType" align="center">
              <template slot-scope="text">
                <a-tag v-if="text == 0" color="#e6a23c">待发货</a-tag>
                <a-tag v-if="text == 1" color="#67c23a">已发货</a-tag>
                <a-tag v-if="text == 2" color="#67c23a">已完成</a-tag>
              </template>
            </a-table-column>
            <a-table-column title="收货人信息" data-index="" :width="400">
              <template slot-scope="row">
                {{ row.consignee }} , {{ row.phone }}<br />
                {{ row.provinceName }}-{{ row.cityName }}-{{ row.areaName }}-{{ row.address }}
              </template>
            </a-table-column>
            <a-table-column title="创建时间" data-index="createTime" align="center" :width="150"></a-table-column>
            <a-table-column title="操作" data-index="" :width="120" align="center">
              <template slot-scope="row">
                <a @click="onCheckOrderShipInfo(row)">查看</a>
                <a v-if="row.deliveryType == 0 && row.shippingOrderStatus == 1" @click="onInvoiceReview(row)">审核</a>
                <a v-if="row.shippingOrderStatus == 2 && row.deliveryType == 1" @click="onCheckLogistics(row)"
                  >查看物流</a
                >
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="operating_info" tab="操作日志">
          <LogPage :orderId="orderId" ref="LogPage"></LogPage>
        </a-tab-pane> -->
      </a-tabs>
      <a-divider orientation="left" v-if="handel == 'audit'">审核</a-divider>
      <div class="order_audit" v-if="handel == 'audit'">
        <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="audit_status" @change="onStatusChange">
              <a-radio :value="4"> 通过 </a-radio>
              <a-radio :value="3"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model="rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="statusRadio == 3" type="danger" :loading="loading" @click="onModifyOrderStatus('refuse')">确定</a-button>
          <a-button v-if="statusRadio == 4" type="primary" :loading="loading" @click="onModifyOrderStatus('audit')">确定</a-button>
        </div>
      </div>
    </a-modal>

    <!-- 审核发货单 -->
    <!-- <InvoiceReview ref="InvoiceReview" @reload="isShow"></InvoiceReview> -->
    <!-- 查看物流 -->
    <!-- <CheckLogistics ref="CheckLogistics"></CheckLogistics> -->
    <!-- 查看发货单 -->
    <!-- <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal> -->

    <!-- 使用折扣明细 -->
    <!-- <a-modal title="使用折扣明细" v-model="rebateVisible" width="70%" :footer="null">
      <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="使用折扣明细">
          <div
            class="checked_inline"
            v-if="detail.dealerRebateUsedDetails && detail.dealerRebateUsedDetails.length > 0"
          >
            <label v-for="(item, index) in detail.dealerRebateUsedDetails" :key="index">
              <span class="checkbox_text">折扣名称：({{ item.rebateName }})</span>
              <span class="checkbox_text">使用折扣金额：{{ item.usedAmount }}</span>
              <span>使用时间：{{ item.createTime }} </span>
            </label>
          </div>
          <div v-else>暂无已使用折扣</div>
        </a-form-model-item>
      </a-form-model>
      <div class="fonnter-btns">
        <a-button type="default" @click="rebateVisible = false">关闭</a-button>
      </div>
    </a-modal> -->
  </div>
</template>

<script>
import { selectByIdOrderInfo, selectShipInfoListByCode, selectByOrderCodeOrderInfo } from '../api/OrderInfoApi'
import LogPage from '@/views/order_info/components/LogPage.vue'
import InvoiceReview from '@/views/order_ship_info/components/InvoiceReview.vue'
import CheckLogistics from '@/views/order_ship_info/components/CheckLogistics.vue'
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal.vue'

const {auditTaskBatch} = require('@/api/activiti');

export default {
  name: '',
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      visible: false,
      loading:false,
      isShowAllInfo: false,
      tableData: [],
      detail: {},
      dealerRebateUsedDetails: [],
      orderShipInfoList: [],
      checkedTabs: 'base_info',
      orderId: '',
      statusRadio: 4,
      audit_status: 4,
      handel: 'check',
      rejectReason: '',
      rebateVisible: false,
      imageUrl: '',
      contractTypeList: [],
      columnsAuditAll: [
        {
          title: '订单编号',
          dataIndex: 'orderCode',
          key: 'orderCode',
        },
        {
          title: '大区/事务所',
          dataIndex: 'bigAreaName',
          key: 'bigAreaName',
          align: 'center',
          scopedSlots: { customRender: 'bigAreaName' },
        },
        {
          title: '客户名称',
          dataIndex: 'dealerName',
          key: 'dealerName',
          align: 'center',
          // ellipsis:true
        },
        {
          title: '政策优惠金额(元)',
          dataIndex: 'policyTotalAmount',
          key: 'policyTotalAmount',
          align: 'center',
          // ellipsis: true,
          scopedSlots: { customRender: 'policyTotalAmount' },
        },
        {
          title: '折扣优惠金额(元)',
          dataIndex: 'rebateTotalAmount',
          key: 'rebateTotalAmount',
          align: 'center',
          // ellipsis: true,
          scopedSlots: { customRender: 'rebateTotalAmount' },
        },
        {
          title: '订单金额(元)',
          dataIndex: 'payAmount',
          key: 'payAmount',

          // ellipsis: true,
          scopedSlots: { customRender: 'payAmount' },
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          // ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  components: {
    LogPage,
    InvoiceReview,
    CheckLogistics,
    OrderShipInfoEditModal,
  },

  computed: {},

  created() {},

  filters: {
    status(type) {
      const typeMap = {
        0: '待审核',
        1: '已关闭',
        2: '已取消',
        3: '审核拒绝',
        4: '已审核待发货',
        5: '部分发货',
        6: '已发货（全部发货）',
        7: '已完成（用户签收）',
        8: '已评价',
        9: '草稿',
      }
      return typeMap[type]
    },
  },

  methods: {
    //   表格
    checkDetails(row) {
      this.dataForm = row
    },
    isvisible(name) {
      this.visible = true
      this.handel = name
      this.isShowAllInfo = false
    },
    // 修改订单状态
    onModifyOrderStatus(name) {
      if (name == 'audit') {
        this.onBatchAudit(2)
      } else if (name == 'refuse') {
        this.onBatchAudit(3)
      }
    },
    // onModifyStatus(status, name) {
    //    this.loading = true
    //   let params = {
    //     authStatus: status,
    //     verifyRemark: this.rejectReason,
    //   }
    //   params.ids = this.dataList.map((item) => item.id)
    //   this.axios.post('/api/order/order/orderInfo/verifyOrderList', params).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success(res.message)
    //       this.visible = false
    //       this.rejectReason = ''
    //       this.loading = false
    //       this.$bus.$emit('getList')
    //     }
    //   })
    // },

    onBatchAudit(status) {
      const _that = this
      _that.loading = true
      auditTaskBatch({ taskIds: _that.dataList.map((item) => item.taskId), result: status ,comment: _that.rejectReason }).then((res) => {
        if (res.code === 200) {
          _that.$message.success('审核成功')
        } else {
          _that.$message.error(res.msg)
        }
        _that.visible = false
        _that.rejectReason = ''
        _that.loading = false
        _that.$emit('getConfirmDiscount')
      })
    },

    isShowAll(row) {
      this.isShowAllInfo = true
      this.orderId = row.id
      this.checkedTabs = 'base_info'
      selectByIdOrderInfo(row.id).then((res) => {
        this.detail = res.body
        this.tableData = res.body.orderInfoDetailVOList != null ? res.body.orderInfoDetailVOList : []
        this.dealerRebateUsedDetails = res.body.dealerRebateUsedDetails != null ? res.body.dealerRebateUsedDetails : []
        this.imageUrl = res.body.filesVOS
          ? res.body.filesVOS
              .map((e) => {
                return e.fileUrl
              })
              .toString()
          : ''
      })
      // selectShipInfoListByCode(row.ShipCode.orderCode).then((res) => {
      //   this.orderShipInfoList = res.body
      // })
    },

    // isShowByOrderCode(row, name) {
    //   this.visible = true
    //   this.orderId = row.id
    //   this.checkedTabs = 'base_info'
    //   this.handel = name
    //   selectByOrderCodeOrderInfo(row.orderCode).then((res) => {
    //     this.detail = res.body
    //     this.orderId = res.body.id
    //     this.tableData = res.body.orderInfoDetailVOList != null ? res.body.orderInfoDetailVOList : []
    //     this.dealerRebateUsedDetails = res.body.dealerRebateUsedDetails != null ? res.body.dealerRebateUsedDetails : []
    //     this.imageUrl = res.body.filesVOS
    //       ? res.body.filesVOS
    //           .map((e) => {
    //             return e.fileUrl
    //           })
    //           .toString()
    //       : ''
    //   }),
    //     selectShipInfoListByCode(row.ShipCode).then((res) => {
    //       this.orderShipInfoList = res.body
    //     })

    //   this.axios.get('/api/base/system/dictionary/selectByCode/' + '合同类型').then((res) => {
    //     // 获取字典合同类型
    //     this.contractTypeList = res.body
    //   })
    // },
    // 查看折扣使用明细
    // onCheckRebateDetail() {
    //   this.rebateVisible = true
    // },

    // onInvoiceReview(row) {
    //   this.$refs.InvoiceReview.show(row, 'audit')
    // },

    callback(key) {
      console.log(key)
      if (key == 'operating_info') {
        this.$refs.LogPage.getList()
      }
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },

    // 查看物流
    // onCheckLogistics(row) {
    //   this.$refs.CheckLogistics.getData(row, 'view_logistics')
    // },

    // 查看发货单
    // onCheckOrderShipInfo(row) {
    //   this.$refs.OrderShipInfoEditModal.setRowData(row, 'check')
    // },

    // 调用修改状态对应接口
    // onModifyStatus(status, name) {
    //   let obj = {
    //     orderId: this.orderId,
    //     orderStatus: status,
    //     verifyRemark: this.rejectReason,
    //   }
    //   this.axios.post(`/api/order/order/orderInfo/verifyOrderInfo`, obj).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success(res.message)
    //       this.visible = false
    //       this.rejectReason = ''
    //       this.$bus.$emit('getList')
    //     }
    //   })
    // },
  },
}
</script>

<style lang='scss' scoped>
.order_title,
.row {
  display: flex;
  justify-content: space-between;
}
.order_title {
  margin-bottom: 10px;
}
.row {
  color: rgba(0, 0, 0, 0.8);
}
.order_code {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}
.split-line {
  height: 13px;
  background: #ececec;
  margin: 15px 0;
  width: 105%;
  margin-left: -24px;
}
.line {
  width: 105%;
  margin: 15px 0;
  height: 1px;
  background: #e9e9e9;
  margin-left: -24px;
}
.client_info {
  &_row {
    flex-wrap: wrap;
  }
  &_title {
    font-size: 16px;
    color: #333333;
    line-height: 10px;
  }
}
.product_info_title {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333333;
}
.footer-bts {
  text-align: right;
}
.discount {
  text-align: right;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.hyperlinks {
  font-size: 12px;
  color: #888888;
  margin-left: 5px;
}
.hyperlinks:hover {
  color: #4398fa;
}
.fonnter-btns {
  text-align: right;
}
.checkbox_text {
  margin-right: 40px;
}
.line_spacing {
  margin-bottom: 10px;
}
</style>