<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <!-- 搜索 -->
      <OrderSearch :showOrderStatus="'order_info'" @getSearchData="getSearchData" ref="OrderSearch"></OrderSearch>
      <div>
        <a-button v-if="isAdd" type="primary" @click="toHandler('add')">添加订单</a-button>
        <a-button v-if="isCheck && selectedRows.length == 1" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="isEdit && selectedRows.length == 1 && selectedRows[0].orderStatus == 9" type="primary" @click="toHandler('edit')">编辑订单</a-button>
        <a-button v-if="isQuick && selectedRows.length == 1" type="primary" @click="toHandler('quick')">复制订单</a-button>
        <a-button
          v-if="isEditAddress && selectedRows.length == 1 && (selectedRows[0].orderStatus == 9 || selectedRows[0].orderStatus == 0)"
          type="primary"
          @click="toHandler('edit_address')"
          >修改地址</a-button
        >
        <a-button
          v-if="isEditDate && selectedRows.length == 1 && (selectedRows[0].orderStatus == 9 || selectedRows[0].orderStatus == 0)"
          type="primary"
          @click="toHandler('edit_date')"
        >修改下单时间</a-button
        >
        <a-button
          v-if="isSubmit && selectedRows.length >= 1 && selectedRows[0].orderStatus == 9"
          type="primary"
          @click="batchSubmitAudit"
        >提交审核</a-button>
        <a-button
          v-if="isEditAudit && selectedRows.length == 1 && selectedRows[0].taskId != null &&  selectedRows[0].orderStatus == 0"
          type="primary"
          @click="toHandler('edit_autid')"
        >修改订单</a-button>
        <a-button
          v-if="isAudit && selectedRows.length == 1 && selectedRows[0].taskId != null &&  selectedRows[0].orderStatus == 0"
          type="primary"
          @click="toHandler('audit')"
          >审核</a-button
        >
        <a-button
          v-if="isAuditList && selectedRows.length > 1 && selectedRows[0].taskId != null && selectedRows[0].orderStatus == 0"
          type="primary"
          @click="toHandlerAll"
          >批量审核</a-button
        >
        <a-button
          v-if="isDiscount && selectedRows.length == 1 && selectedRows[0].confirmDiscount != true && selectedRows[0].orderStatus == 4"
          type="primary"
          @click="onShowConfirmModal"
          >确认折扣率</a-button
        >
        <a-button
          v-if="
            isLGContract &&
            selectedRows.length == 1 &&
            selectedRows[0].orderStatus == 4 &&
            selectedRows[0].confirmDiscount &&
            (selectedRows[0].supplierCode == 'linkgap' || selectedRows[0].stContractNum != null) &&
            selectedRows[0].linkGapContractNum == null
          "
          type="primary"
          @click="onShowScLinkgapModal"
          >生成销售合同</a-button
        >
        <a-button
          v-if="
            isLGContract &&
            selectedRows.length == 1 &&
            selectedRows[0].confirmDiscount &&
            selectedRows[0].linkGapContractNum  != null"
          type="primary"
          @click="onShowScLinkgapModal"
        >重新生成销售合同</a-button>
        <a-button
          v-if="
            isSTContract &&
            selectedRows.length == 1 &&
            selectedRows[0].confirmDiscount &&
            selectedRows[0].supplierCode != 'linkgap' &&
            selectedRows[0].stContractNum == null &&
            selectedRows[0].flagExport == true &&
            selectedRows[0].orderStatus == 4
          "
          type="primary"
          @click="onShowScStModal"
          >生成采购合同</a-button
        >
        <a-button
          v-if="
            isSTContract &&
            selectedRows.length == 1 &&
            selectedRows[0].confirmDiscount &&
            selectedRows[0].supplierCode != 'linkgap' &&
            selectedRows[0].stContractNum != null &&
            selectedRows[0].flagExport == true
          "
          type="primary"
          @click="onShowScStModal"
        >重新生成采购合同</a-button>
        <a-button v-if="isVoid && selectedRows.length == 1 && selectedRows[0].orderStatus == 5" type="primary" @click="toHandler('void')">
          关闭订单</a-button>
        <a-button v-if="isCancel && selectedRows.length == 1 && selectedRows[0].shipCount <= 0 && selectedRows[0].orderStatus != 1 && selectedRows[0].orderStatus != 2 && selectedRows[0].orderStatus != 5" type="primary" @click="toHandler('cancel')">
          取消订单</a-button
        >
        <a-button v-if="isDelete && selectedRows.length == 1 && selectedRows[0].orderStatus == 9" type="danger" @click="toHandler('del')">删除</a-button>
        <a-button
          v-if="isExportLGContract && selectedRows.length == 1 && selectedRows[0].linkGapContractNum != null && selectedRows[0].confirmDiscount"
          @click="downHt('lght')"
          icon="download"
        >下载销售合同</a-button
        >
        <a-button
          v-if="isExportSTContract && selectedRows.length == 1 && selectedRows[0].stContractNum != null && selectedRows[0].confirmDiscount"
          @click="downHt('stht')"
          icon="download"
        >下载采购合同</a-button
        >
        <a-button
          v-if="isAuditRoll && selectedRows.length == 1 && selectedRows[0].orderStatus == 0"
          type="default"
          @click="toHandler('rollbackStatus')"
          icon="rollback"
          >撤审</a-button>

        <a-button
          v-if="isAuditBack && selectedRows.length == 1 && selectedRows[0].shipCount <= 0 && (selectedRows[0].orderStatus == 0 || selectedRows[0].orderStatus == 4)"
          type="default"
          @click="toHandler('orderFallback')"
          icon="rollback"
        >回退</a-button>

<!--        <a-button-->
<!--          v-if="isExportContract && selectedRows.length == 1 && selectedRows[0].supplierId == 11 && selectedRows[0].orderStatus == 4"-->
<!--          type="default"-->
<!--          @click="toHandler('export')"-->
<!--          >导出合同-->
<!--        </a-button>-->
        <downLoad
          method="get"
          api="/api/order/order/orderInfo/excelTakeGoodsBill"
          :params="{id:selectedRows[0].id,port:port}"
          name="提货单.xlsx"
          @downLoadDone="downLoadDone"
          @startDownLoad="startDownLoad"
          v-if="
            selectedRows.length == 1 &&
            selectedRows[0].confirmDiscount &&
            selectedRows[0].supplierCode != 'linkgap'"
        >导出提货单</downLoad
        >
        <!--  &&
            selectedRows[0].stContractNum == null&&
            selectedRows[0].flagExport == false &&
            selectedRows[0].orderStatus == 4-->
        <downLoad
          method="get"
          api="/api/order/order/orderInfo/exportSingleOrderInfo"
          :params="{id:selectedRows[0].id}"
          name="发货单.xlsx"
          @downLoadDone="downLoadDone"
          @startDownLoad="startDownLoad"
          v-if="selectedRows.length == 1"
        >导出发货单</downLoad
        >
        <!--        isExportSingleShipOrderInfo &&-->
        <a-button
        name="菱感销售单.xls"
        @click="getDownLoadDone"
        v-if="isExport">
        批量导出订单</a-button>
        <downLoad
          method="get"
          api="/api/order/order/orderInfo/excelExportRowOrderInfo"
          @downLoadDone="downLoadDone"
          @startDownLoad="startDownLoad"
          :params="{id:selectedRows[0].id}"
          name="排货单.xlsx"
          v-if="selectedRows.length == 1"
        >导出排货单</downLoad>
<!--        <downLoad-->
<!--          method="post"-->
<!--          api="/api/order/order/orderInfo/excelRebateDeliveryInfos"-->
<!--          :params="searchDataObj"-->
<!--          @downLoadDone="downLoadDone"-->
<!--          @startDownLoad="startDownLoad"-->
<!--          name="推广费及返利提货明细.xls"-->
<!--          v-if="isExport"-->
<!--        >导出返利提货</downLoad-->
<!--        >-->
        <a-upload
          name="file"
          :action="uploadUrl"
          :headers="headers"
          :showUploadList="false"
          @change="uploadChange"
        >
          <a-button icon="upload" type="default">导入</a-button>
        </a-upload>
        <a-button type="default" icon="download"  @click="downLoadTemp">
          <slot>下载导入模版</slot>
        </a-button>
      </div>
      <!-- 数据表格 -->
      <OrderListAll
        :url="'/api/order/order/orderInfo/list'"
        :columnsData="columns"
        :status="[]"
        @orderData="getSelectedRows($event)"
        @orderDataKey="getKeys"
        @changeTable="getchangeTable"
      ></OrderListAll>

      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal" @getConfirmDiscount="getConfirmDiscount"></OrderInfo-edit-modal>
      <OrderInfoEditAndAutid ref="OrderInfoEditAndAutid" @getConfirmDiscount="getConfirmDiscount"></OrderInfoEditAndAutid>
      <orderInfoQuick ref="orderInfoQuick" @getConfirmDiscount="getConfirmDiscount"></orderInfoQuick>
      <EditAddressModal ref="EditAddressModal" @getConfirmDiscount="getConfirmDiscount"></EditAddressModal>
      <EditDateModal ref="EditDateModal" @getConfirmDiscount="getConfirmDiscount"></EditDateModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <GenerateInvoice ref="GenerateInvoice"></GenerateInvoice>
      <OrderInfoAutidAllModal ref="OrderInfoAutidAllModal" :dataList="dataList" @getConfirmDiscount="getConfirmDiscount"></OrderInfoAutidAllModal>
      <!-- 确认折扣率 -->
      <ConfirmDiscount ref="ConfirmDiscount" @getConfirmDiscount="getConfirmDiscount"></ConfirmDiscount>

      <!-- 生成销售合同 -->
      <GenerateLinkgapContract ref="GenerateLinkgapContract" @getConfirmDiscount="getConfirmDiscount"></GenerateLinkgapContract>

      <!-- 生成采购合同 -->
      <GenerateStContract ref="GenerateStContract" @getConfirmDiscount="getConfirmDiscount"></GenerateStContract>

      <a-modal
        title="操作"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        :maskClosable="false"
        @cancel="visible = false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <div v-if="handle == 'audit'">
            <a-form-model-item label="是否通过">
              <a-radio-group v-model="status" @change="onStatusChange">
                <a-radio :value="4"> 通过</a-radio>
                <a-radio :value="3"> 拒绝</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="原因" v-if="statusRadio == 3">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
          <div v-if="handle == 'cancel' || handle == 'void'">
            <a-form-model-item label="理由">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">确认关闭</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit' && statusRadio == 3" type="danger" @click="onModifyOrderStatus('refuse')"
            >确定
          </a-button>
          <a-button v-if="handle == 'audit' && statusRadio == 4" type="primary" @click="onModifyOrderStatus('audit')"
            >确定
          </a-button>
        </div>
      </a-modal>
      <a-modal
        title="请选择导出列"
        width="50%"
        :visible="isShowLoading"
        @cancel="handleCancel"
        :footer="null"
        >
          <div class="warpper-radio">
              <div class="all-check">
                <section>
                  <span>全选：</span>
                  <a-checkbox  :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"></a-checkbox>
                </section>
              </div>
              <div class="list-serial-number">
                <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange" />
              </div>
              <br>
<!--              <hr>-->
              <div class="footer-bts">
              <a-button @click="handleCancel">取消</a-button>
              <downLoad
                method="POST"
                api="/api/order/order/orderInfo/excelExportOrderInfo"
                :params="searchDataObj"
                @downLoadDone="downLoadDone"
                @startDownLoad="startDownLoad"
                :isConfirmLoading="isConfirmLoading"
                :type="primary"
                name="菱感销售单.xls"
                v-if="isExport"
                >导出</downLoad>
              </div>
          </div>
      </a-modal>
    </div>
  </a-card>
  </a-spin>
</template>

<script>
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModalExplabe.vue'
import OrderInfoEditAndAutid from '@/views/order_info/components/OrderInfoEditAndAutid.vue'
import orderInfoQuick from './components/orderInfoQuick.vue'
import { delOrderInfo, rollbackStatus, orderFallback } from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import EditDateModal from './components/EditDateModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { checkPermission } from '@/utils/permissions'
import GenerateInvoice from '../to_delivery_order/components/GenerateInvoice.vue'
import OrderInfoAutidAllModal from './components/OrderInfoAutidAllModal.vue'
import GenerateLinkgapContract from '@/views/audit_finish_order/components/GenerateLinkgapContract.vue'
import GenerateStContract from '@/views/audit_finish_order/components/GenerateStContract'
import ConfirmDiscount from '@/views/audit_finish_order/components/ConfirmDiscount.vue'
import {port} from '@/utils/resour'
import {baseURL} from '@/utils/request';
import {ORDER_TEMP} from '@/utils/downloadTempHref';

export default {
  name: 'orderInfo',
  components: {
    OrderInfoEditModal,
    OrderInfoEditAndAutid,
    orderInfoQuick,
    EditAddressModal,
    EditDateModal,
    InvoiceEditModal,
    OrderInfoCheckModal,
    GenerateInvoice,
    OrderInfoAutidAllModal,
    GenerateLinkgapContract,
    GenerateStContract,
    ConfirmDiscount,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'bigAreaName',
        'dealerName',
        'belongDealerName',
        'flagFreeShipping',
        'deliveryProperties',
        'purchaseAndSalesContractType',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'expectTime',
        'createTime',
        'orderDate',
        'address',
        'consigneeType',
        'supplierName',
        'policyTotalAmount',
        'rebateTotalAmount',
        'linkGapContractNum',
        'stContractNum',
        'goodsCount',
        'alreadySendGoodsCount',
        'bucklePoint'
      ],
      visibleToHandlerAuditAll: false,
      tableData: [],
      searchData: {}, // 搜索条件
      port: port,
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isDiscount: checkPermission('order:info:discount'),
      isLGContract: checkPermission('order:info:create_lg_contract'),
      isSTContract: checkPermission('order:info:create_st_contract'),
      isExportLGContract: checkPermission('order:info:export_lg_contract_audit_finish'),
      isExportSTContract: checkPermission('order:info:export_st_contract_audit_finish'),
      isExportContract: checkPermission('order:info:export_contract'),
      isExport: checkPermission('order:info:export'),
      isAdd: checkPermission('order:info:add'),
      isEdit: checkPermission('order:info:edit'),
      isQuick:checkPermission('order:info:isQuick'),
      isDelete: checkPermission('order:info:delete'),
      isEditAddress: checkPermission('order:info:edit_address'),
      isEditDate: checkPermission('order:info:edit_date'),
      // isEditInvoice: checkPermission('order:info:edit_invoice'),
      isCheck: checkPermission('order:info:check'),
      isAudit: checkPermission('order:info:audit'),
      isEditAudit: checkPermission('order:info:edit_audit'),
      isAuditList: checkPermission('order:info:audit_list'),
      isAuditRoll: checkPermission('order:info:audit_roll'),
      isAuditBack: checkPermission('order:info:audit_back'),
      isCancel: checkPermission('order:info:cancel'),
      isVoid: checkPermission('order:info:void'),
      isSubmit: checkPermission('order:info:submit'),
      isExportTakeGoodsBill: checkPermission('order:info:export_take_goods_bill'),
      visible: false,
      confirmLoading: false,
      HandlerAll: true,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
      status: 4,
      spinning:false,
      statusRadio: 4,
      columnsAuditAll: [
        {
          title: '订单编号',
          dataIndex: 'orderCode',
          key: 'orderCode',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataList: [],
      dataForm: {},
      idsList:[],
      listdateString:[],
      //上传文件地址-导入老系统订单数据
      // uploadUrl: baseURL + '/api/order/order/orderInfo/excelReader',
      uploadUrl: baseURL + '/api/order/order/orderInfo/excelReaderNewOrder',
      downloadTempHref: ORDER_TEMP,
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      primary:'primary',
      isConfirmLoading:false,
      isShowLoading: false,
      checkAll: false,
      indeterminate: true,
      checkedList:[],
      defaultCheckedList:['序号', '订单编号', '订单状态', '客户名称', '大区', '事务所', '发货性质', '购货方式', '订单类型', '订单日期', '订单备注','商品编码', '商品名称', '规格型号', '商品分类', '品牌', '标签', '数量','已发数量','未发数量', '单位', '标准开票价（D价）','政策单价','折扣额',  '实际成交价',  '标准开票价金额','实付金额', '机型', '备注', '销售合同号', '采购合同号', '收货人信息'],
      plainOptions:['序号', '订单编号', '订单状态', '客户名称', '大区', '事务所', '发货性质', '购货方式', '订单类型', '订单日期', '订单备注','商品编码', '商品名称', '规格型号', '商品分类', '品牌', '标签', '数量','已发数量','未发数量', '单位', '标准开票价（D价）','政策单价','折扣额',  '实际成交价',  '标准开票价金额','实付金额', '机型', '备注', '销售合同号', '采购合同号', '收货人信息']
    }
  },
  computed:{
    searchDataObj(){
      const obj = {
        column:[JSON.parse(JSON.stringify(this.checkedList))],
        ...this.searchData,
        ids:this.idsList,
      }
      return obj
    }
  },
  methods: {
    // 批量导出
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    handleCancel() {
      this.isShowLoading = false;
    },
    getDownLoadDone() {
      this.checkedList = this.defaultCheckedList
      this.isShowLoading = true;
    },
    //
    downLoadTemp (){
      window.open(this.downloadTempHref, '_blank')
    },
    startDownLoad(){
      this.isConfirmLoading = true;
      this.spinning = true
    },
    downLoadDone (){
      this.isConfirmLoading = false;
      this.isShowLoading = false;
      this.spinning = false
    },
    //导入
    uploadChange (info) {
      this.spinning  = true
      setTimeout(()=>{
        if(info.file.response){
          let hint = info.file.response
          if(hint.code === 200) {
            this.spinning  =  false
            this.$message.success(hint.message)
            this.$refs.OrderSearch.getDataFilter()
            // this.getData()
            console.log('--------',hint.body)
            if(hint.body != null){
                this.startDownLoad();
                function createObjectURL(object) {
                  return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(
                    object);
                }
                var xhr = new XMLHttpRequest();

                xhr.open(`post`, `/api/order/order/orderInfo/excelExportErrorOrderInfo`);
                xhr.setRequestHeader('Authorization-Admin', this.$store.getters.token);
                xhr.responseType = 'blob';
                var that=this
                xhr.onload = function (e){
                  if (this.status == 200) {
                    that.downLoadDone();
                    var blob = this.response;
                    var filename = '错误订单信息.xlsx';
                    if (window.navigator.msSaveOrOpenBlob) {
                      navigator.msSaveBlob(blob, filename);
                    } else {
                      var a = document.createElement('a');
                      var url = createObjectURL(blob);
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  }
                };
                xhr.send(JSON.stringify(hint.body));
            }
          }else if(hint.code === 500) {
            this.spinning  =  false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })

    },
    getConfirmDiscount(){
      this.$refs.OrderSearch.getDataFilter()
    },
    getchangeTable(){
      this.$refs.OrderSearch.getDataFilter(1)
    },
    // 导出条件
    getSearchData(row){
     this.searchData = row
     this.idsList=[]
    },
    getKeys(row){
     this.idsList = row
    },
     getSelectedRows(rows) {
      this.selectedRows = rows
    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.orderStatus === 0 && item.taskId != null
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        this.$refs.OrderInfoAutidAllModal.isvisible('audit')
        this.$bus.$emit('getList')
      }else{
        this.$notification.error({ message: '请选择相同待审核记录' })
      }
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    // 打开确认折扣率弹框
    onShowConfirmModal() {
      this.$refs.ConfirmDiscount.onShow(this.selectedRows[0])
    },

    // 打开生成销售合同按钮
    onShowScLinkgapModal() {
      this.$refs.GenerateLinkgapContract.onShow(this.selectedRows[0])
    },

    // 打开生成采购合同按钮
    onShowScStModal() {
      this.$refs.GenerateStContract.onShow(this.selectedRows[0])
    },



    downHt(flag) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      var path = ''
      if(flag === 'lght'){
        path = _this.selectedRows[0].linkGapContractUrl
      }else{
        path = _this.selectedRows[0].stContractUrl
      }
      this.axios.get('/api/order/order/orderInfo/checkContractExist?path='+path).then((res) => {
        if (res.code == 200) {
          if(res.body == 200){
            const routeData = this.$router.resolve({
              path: '/api/order/order/orderInfo/contract/download',
              query: {
                orderId: _this.selectedRows[0].id,
                flag: flag,
              },
            })

            window.open(routeData.href, '_blank')
          }else{
            //文件丢失，重新生成
            _this.$confirm({
              title: '合同丢失了',
              content: '是否重新生成合同?',
              okText: '重新生成',
              cancelText: '取消',
              async onOk() {
                if(flag === 'lght'){
                  _this.onShowScLinkgapModal();
                }else{
                  _this.onShowScStModal();
                }
              },
              onCancel() {},
            })
          }

        }
      })

    },


    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this

      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请至少选择一条记录',
        })
        return
      }

      const everyResult = this.selectedRows.every((item) => {
        return item.orderStatus === 9
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.spinning  = true
            const query = {
              ids : _this.dataList.map((item) => item.id)
            }
            _this.axios.post('/api/order/order/orderInfo/batchSubmitAudit', query).then(res => {
              _this.$notification.success({ message: res.message })
              _this.$refs.OrderSearch.getDataFilter()
              _this.selectedRows = []
              _this.selectedRowKeys = []
              _this.spinning  = false
            }).catch(err =>{
              _this.$refs.OrderSearch.getDataFilter()
              _this.selectedRows = []
              _this.selectedRowKeys = []
              _this.spinning  = false
            })
          },
          onCancel() {
          }
        })
      }else{
        this.$notification.error({ message: '请选择未提交审核记录' })
      }
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
          case 'edit_autid':
          _this.$refs.OrderInfoEditAndAutid.setRowData(_this.selectedRows[0], 'edit_autid')
          break
      case 'quick':
          _this.$refs.orderInfoQuick.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.selectedRows = []
                _this.selectedRowKeys = []
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.selectedRows = []
                _this.selectedRowKeys = []
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'orderFallback':
          _this.$confirm({
            title: '订单回退警告',
            content: '确认要回退此条订单至草稿状态吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await orderFallback(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.selectedRows = []
                _this.selectedRowKeys = []
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.selectedRows = []
                _this.selectedRowKeys = []
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_date':
          _this.$refs.EditDateModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'sbm_audit':
          _this.visible = true
          _this.handle = 'sbm_audit'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'check')
          break
        case 'generate_invoice':
          _this.$refs.GenerateInvoice.setRowData(_this.selectedRows[0], 'generate')
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      if (name == 'audit') {
        this.onModifyStatus(4, 'audit')
      } else if (name == 'refuse') {
        this.onModifyStatus(3, 'refuse')
      } else if (name == 'void') {
        this.onModifyStatus(1, 'void')
      } else if (name == 'cancel') {
        this.onModifyStatus(2, 'cancel')
      } else if (name == 'sbm_audit') {
        this.onModifyStatus(0, 'sbm_audit')
      }
    },

    // 调用修改状态对应接口
    onModifyStatus(status, name) {
      let obj = {
        orderId: this.selectedRows[0].id,
        orderStatus: status,
        verifyRemark: this.rejectReason,
      }
      let url =
        name === 'cancel'
          ? '/api/order/order/orderInfo/cancelOrderInfo'
          : name === 'void'
          ? '/api/order/order/orderInfo/invalidOrderInfo'
          : '/api/order/order/orderInfo/verifyOrderInfo'
      this.axios.post(url, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.rejectReason = ''
          this.$refs.OrderSearch.getDataFilter()
          this.selectedRowKeys = []
        }
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
::v-deep .ant-checkbox-group .ant-checkbox-group-item {
  width: 180px;
}
.footer-bts {
  text-align: right;
}
.ant-input-number-handler-wrap {
  display: none;
}

.skip {
  display: inline-block;
  width: 130px;
  height: 34px;
  border: 1px solid #d9d9d9;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  text-align: center;
  line-height: 34px;
  border-radius: 2px;
  background: #ffffff;
  margin-right: 10px;
  padding: 0 10px;
  a {
    color: rgba(0, 0, 0, 0.65);
    margin-left: 8px;
  }
}
</style>
